import * as React from "react";
import { graphql } from "gatsby";
import ComponentTemplatePage from "../../../../components/pages/templates/ComponentTemplatePage";

const ComponentTemplate = ({ data }) => (
  <ComponentTemplatePage
    title="Footer Components | UI Components Library | Uizard"
    category="Component Templates"
    url="/templates/component-templates/footer/"
    metaDescription="Whatever footer you need, Uizard's array of ready-to-use footer UI component templates can be easily incorporated into any app or website design."
    description="
    h2:Pre-made footers for your mobile and web apps
    <br/>
    Drag-and-drop footer component template for any digital project!
    <br/>
    We've taken a look at thousands of websites and apps and gathered the most common footer design patterns.
    All the standard footers following best practices are available as pre-made component template in Uizard!
    Always at your disposal and just one click away!
    <br/>
    Whether it's a navigation, web shop, or audio player footer, Uizard's array of ready-to-use footer component template can be easily incorporated into any app or website design.
    <br/>
    h3:Find the footer component template that best complements your design, and make minor adjustments
    <br/>
    Choose from a myriad of footer components, customize it to your own liking, then voila, your design is ready.
    "
    pages={[
      "Footer work message app",
      "Web shop footer",
      "Audio player footer",
      "App footer overlay button",
      "Asymmetric app footer ",
      "Footer app nav icons",
      "Footer app nav icons and text",
    ]}
    img1={data.image1.childImageSharp}
    img1alt="Screenshot showcasing the component template for designing app footers"
    img2={data.image2.childImageSharp}
    img2alt="Screenshot showcasing the component template for designing app footers"
    img3={data.image3.childImageSharp}
    img3alt="Screenshot showcasing the component template for designing app footers"
    img4={data.image4.childImageSharp}
    img4alt="Screenshot showcasing the component template for designing app footers"
    img5={data.image5.childImageSharp}
    img5alt="Screenshot showcasing the component template for designing app footers"
  />
);

export default ComponentTemplate;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: { eq: "templates/component-templates/footer/footer_1.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: { eq: "templates/component-templates/footer/footer_2.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: { eq: "templates/component-templates/footer/footer_3.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: { eq: "templates/component-templates/footer/footer_4.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: { eq: "templates/component-templates/footer/footer_5.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
